import React from "react";

export const CountrySelect = props => {
  const { translations } = props;

  return (
    <select name="Country" {...props}>
      <option value="">--{translations["Select Your Country"]}--</option>
      <option value="Afghanistan">{translations["Afghanistan"]}</option>
      <option value="Albania">{translations["Albania"]}</option>
      <option value="Algeria">{translations["Algeria"]}</option>
      <option value="American Samoa">{translations["American Samoa"]}</option>
      <option value="Andorra">{translations["Andorra"]}</option>
      <option value="Anguilla">{translations["Anguilla"]}</option>
      <option value="Antarctica">{translations["Antarctica"]}</option>
      <option value="Antigua And Barbuda">
        {translations["Antigua And Barbuda"]}
      </option>
      <option value="Argentina">{translations["Argentina"]}</option>
      <option value="Armenia">{translations["Armenia"]}</option>
      <option value="Aruba">{translations["Aruba"]}</option>
      <option value="Australia">{translations["Australia"]}</option>
      <option value="Austria">{translations["Austria"]}</option>
      <option value="Azerbaijan">{translations["Azerbaijan"]}</option>
      <option value="Bahamas">{translations["Bahamas"]}</option>
      <option value="Bahrain">{translations["Bahrain"]}</option>
      <option value="Bangladesh">{translations["Bangladesh"]}</option>
      <option value="Barbados">{translations["Barbados"]}</option>
      <option value="Belarus">{translations["Belarus"]}</option>
      <option value="Belgium">{translations["Belgium"]}</option>
      <option value="Belize">{translations["Belize"]}</option>
      <option value="Benin">{translations["Benin"]}</option>
      <option value="Bermuda">{translations["Bermuda"]}</option>
      <option value="Bhutan">{translations["Bhutan"]}</option>
      <option value="Bolivia">{translations["Bolivia"]}</option>
      <option value="Bosnia and Herzegovina">
        {translations["Bosnia and Herzegovina"]}
      </option>
      <option value="Botswana">{translations["Botswana"]}</option>
      <option value="Bouvet Island">{translations["Bouvet Island"]}</option>
      <option value="Brazil">{translations["Brazil"]}</option>
      <option value="British Indian Ocean Territory">
        {translations["British Indian Ocean Territory"]}
      </option>
      <option value="Brunei Darussalam">
        {translations["Brunei Darussalam"]}
      </option>
      <option value="Bulgaria">{translations["Bulgaria"]}</option>
      <option value="Burkina Faso">{translations["Burkina Faso"]}</option>
      <option value="Burundi">{translations["Burundi"]}</option>
      <option value="Cambodia">{translations["Cambodia"]}</option>
      <option value="Cameroon">{translations["Cameroon"]}</option>
      <option value="Canada">{translations["Canada"]}</option>
      <option value="Cape Verde">{translations["Cape Verde"]}</option>
      <option value="Cayman Islands">{translations["Cayman Islands"]}</option>
      <option value="Central African Republic">
        {translations["Central African Republic"]}
      </option>
      <option value="Chad">{translations["Chad"]}</option>
      <option value="Chile">{translations["Chile"]}</option>
      <option value="China">{translations["China"]}</option>
      <option value="Christmas Island">
        {translations["Christmas Island"]}
      </option>
      <option value="Cocos (Keeling) Islands">
        {translations["Cocos (Keeling) Islands"]}
      </option>
      <option value="Colombia">{translations["Colombia"]}</option>
      <option value="Comoros">{translations["Comoros"]}</option>
      <option value="Congo">{translations["Congo"]}</option>
      <option value="Congo, the Democratic Republic of the">
        {translations["Congo, the Democratic Republic of the"]}
      </option>
      <option value="Cook Islands">{translations["Cook Islands"]}</option>
      <option value="Costa Rica">{translations["Costa Rica"]}</option>
      <option value="Cote d'Ivoire">{translations["Cote d'Ivoire"]}</option>
      <option value="Croatia">{translations["Croatia"]}</option>
      <option value="Cyprus">{translations["Cyprus"]}</option>
      <option value="Czech Republic">{translations["Czech Republic"]}</option>
      <option value="Denmark">{translations["Denmark"]}</option>
      <option value="Djibouti">{translations["Djibouti"]}</option>
      <option value="Dominica">{translations["Dominica"]}</option>
      <option value="Dominican Republic">
        {translations["Dominican Republic"]}
      </option>
      <option value="East Timor">{translations["East Timor"]}</option>
      <option value="Ecuador">{translations["Ecuador"]}</option>
      <option value="Egypt">{translations["Egypt"]}</option>
      <option value="El Salvador">{translations["El Salvador"]}</option>
      <option value="Equatorial Guinea">
        {translations["Equatorial Guinea"]}
      </option>
      <option value="Estonia">{translations["Estonia"]}</option>
      <option value="Ethiopia">{translations["Ethiopia"]}</option>
      <option value="Falkland Islands">
        {translations["Falkland Islands"]}
      </option>
      <option value="Faroe Islands">{translations["Faroe Islands"]}</option>
      <option value="Fiji">{translations["Fiji"]}</option>
      <option value="Finland">{translations["Finland"]}</option>
      <option value="France">{translations["France"]}</option>
      <option value="French Guiana">{translations["French Guiana"]}</option>
      <option value="French Polynesia">
        {translations["French Polynesia"]}
      </option>
      <option value="French Southern Territories">
        {translations["French Southern Territories"]}
      </option>
      <option value="Gabon">{translations["Gabon"]}</option>
      <option value="Gambia">{translations["Gambia"]}</option>
      <option value="Georgia">{translations["Georgia"]}</option>
      <option value="Germany">{translations["Germany"]}</option>
      <option value="Ghana">{translations["Ghana"]}</option>
      <option value="Gibraltar">{translations["Gibraltar"]}</option>
      <option value="Great Britain">{translations["Great Britain"]}</option>
      <option value="Greece">{translations["Greece"]}</option>
      <option value="Greenland">{translations["Greenland"]}</option>
      <option value="Grenada">{translations["Grenada"]}</option>
      <option value="Guadeloupe">{translations["Guadeloupe"]}</option>
      <option value="Guam">{translations["Guam"]}</option>
      <option value="Guatemala">{translations["Guatemala"]}</option>
      <option value="Guinea">{translations["Guinea"]}</option>
      <option value="Guinea-Bissau">{translations["Guinea-Bissau"]}</option>
      <option value="Guyana">{translations["Guyana"]}</option>
      <option value="Haiti">{translations["Haiti"]}</option>
      <option value="Heard and Mc Donald Islands">
        {translations["Heard and Mc Donald Islands"]}
      </option>
      <option value="Honduras">{translations["Honduras"]}</option>
      <option value="Hungary">{translations["Hungary"]}</option>
      <option value="Iceland">{translations["Iceland"]}</option>
      <option value="India">{translations["India"]}</option>
      <option value="Indonesia">{translations["Indonesia"]}</option>
      <option value="Ireland">{translations["Ireland"]}</option>
      <option value="Israel">{translations["Israel"]}</option>
      <option value="Italy">{translations["Italy"]}</option>
      <option value="Jamaica">{translations["Jamaica"]}</option>
      <option value="Japan">{translations["Japan"]}</option>
      <option value="Jordan">{translations["Jordan"]}</option>
      <option value="Kazakhstan">{translations["Kazakhstan"]}</option>
      <option value="Kenya">{translations["Kenya"]}</option>
      <option value="Kiribati">{translations["Kiribati"]}</option>
      <option value="Kuwait">{translations["Kuwait"]}</option>
      <option value="Kyrgyzstan">{translations["Kyrgyzstan"]}</option>
      <option value="Lao People's Democratic Republic">
        {translations["Lao People's Democratic Republic"]}
      </option>
      <option value="Latvia">{translations["Latvia"]}</option>
      <option value="Lebanon">{translations["Lebanon"]}</option>
      <option value="Lesotho">{translations["Lesotho"]}</option>
      <option value="Liberia">{translations["Liberia"]}</option>
      <option value="Libya">{translations["Libya"]}</option>
      <option value="Liechtenstein">{translations["Liechtenstein"]}</option>
      <option value="Lithuania">{translations["Lithuania"]}</option>
      <option value="Luxembourg">{translations["Luxembourg"]}</option>
      <option value="Macau">{translations["Macau"]}</option>
      <option value="Macedonia">{translations["Macedonia"]}</option>
      <option value="Madagascar">{translations["Madagascar"]}</option>
      <option value="Malawi">{translations["Malawi"]}</option>
      <option value="Malaysia">{translations["Malaysia"]}</option>
      <option value="Maldives">{translations["Maldives"]}</option>
      <option value="Mali">{translations["Mali"]}</option>
      <option value="Malta">{translations["Malta"]}</option>
      <option value="Marshall Islands">
        {translations["Marshall Islands"]}
      </option>
      <option value="Martinique">{translations["Martinique"]}</option>
      <option value="Mauritania">{translations["Mauritania"]}</option>
      <option value="Mauritius">{translations["Mauritius"]}</option>
      <option value="Mayotte">{translations["Mayotte"]}</option>
      <option value="Mexico">{translations["Mexico"]}</option>
      <option value="Micronesia, Federated States of">
        {translations["Micronesia, Federated States of"]}
      </option>
      <option value="Moldova, Republic of">
        {translations["Moldova, Republic of"]}
      </option>
      <option value="Monaco">{translations["Monaco"]}</option>
      <option value="Mongolia">{translations["Mongolia"]}</option>
      <option value="Montserrat">{translations["Montserrat"]}</option>
      <option value="Morocco">{translations["Morocco"]}</option>
      <option value="Mozambique">{translations["Mozambique"]}</option>
      <option value="Myanmar">{translations["Myanmar"]}</option>
      <option value="Namibia">{translations["Namibia"]}</option>
      <option value="Nauru">{translations["Nauru"]}</option>
      <option value="Nepal">{translations["Nepal"]}</option>
      <option value="Netherlands">{translations["Netherlands"]}</option>
      <option value="Netherlands Antilles">
        {translations["Netherlands Antilles"]}
      </option>
      <option value="New Caledonia">{translations["New Caledonia"]}</option>
      <option value="New Zealand">{translations["New Zealand"]}</option>
      <option value="Nicaragua">{translations["Nicaragua"]}</option>
      <option value="Niger">{translations["Niger"]}</option>
      <option value="Nigeria">{translations["Nigeria"]}</option>
      <option value="Niue">{translations["Niue"]}</option>
      <option value="Norfolk Island">{translations["Norfolk Island"]}</option>
      <option value="Northern Ireland">
        {translations["Northern Ireland"]}
      </option>
      <option value="Northern Mariana Islands">
        {translations["Northern Mariana Islands"]}
      </option>
      <option value="Norway">{translations["Norway"]}</option>
      <option value="Oman">{translations["Oman"]}</option>
      <option value="Pakistan">{translations["Pakistan"]}</option>
      <option value="Palau">{translations["Palau"]}</option>
      <option value="Panama">{translations["Panama"]}</option>
      <option value="Papua New Guinea">
        {translations["Papua New Guinea"]}
      </option>
      <option value="Paraguay">{translations["Paraguay"]}</option>
      <option value="Peru">{translations["Peru"]}</option>
      <option value="Philippines">{translations["Philippines"]}</option>
      <option value="Pitcairn">{translations["Pitcairn"]}</option>
      <option value="Poland">{translations["Poland"]}</option>
      <option value="Portugal">{translations["Portugal"]}</option>
      <option value="Puerto Rico">{translations["Puerto Rico"]}</option>
      <option value="Qatar">{translations["Qatar"]}</option>
      <option value="Reunion">{translations["Reunion"]}</option>
      <option value="Romania">{translations["Romania"]}</option>
      <option value="Russia">{translations["Russia"]}</option>
      <option value="Russian Federation">
        {translations["Russian Federation"]}
      </option>
      <option value="Rwanda">{translations["Rwanda"]}</option>
      <option value="Saint Kitts and Nevis">
        {translations["Saint Kitts and Nevis"]}
      </option>
      <option value="Saint Lucia">{translations["Saint Lucia"]}</option>
      <option value="Saint Vincent and the Grenadines">
        {translations["Saint Vincent and the Grenadines"]}
      </option>
      <option value="Samoa (Independent)">
        {translations["Samoa (Independent)"]}
      </option>
      <option value="San Marino">{translations["San Marino"]}</option>
      <option value="Sao Tome and Principe">
        {translations["Sao Tome and Principe"]}
      </option>
      <option value="Saudi Arabia">{translations["Saudi Arabia"]}</option>
      <option value="Scotland">{translations["Scotland"]}</option>
      <option value="Senegal">{translations["Senegal"]}</option>
      <option value="Serbia and Montenegro">
        {translations["Serbia and Montenegro"]}
      </option>
      <option value="Seychelles">{translations["Seychelles"]}</option>
      <option value="Sierra Leone">{translations["Sierra Leone"]}</option>
      <option value="Singapore">{translations["Singapore"]}</option>
      <option value="Slovakia">{translations["Slovakia"]}</option>
      <option value="Slovenia">{translations["Slovenia"]}</option>
      <option value="Solomon Islands">{translations["Solomon Islands"]}</option>
      <option value="Somalia">{translations["Somalia"]}</option>
      <option value="South Africa">{translations["South Africa"]}</option>
      <option value="South Georgia and the South Sandwich Islands">
        {translations["South Georgia and the South Sandwich Islands"]}
      </option>
      <option value="South Korea">{translations["South Korea"]}</option>
      <option value="Spain">{translations["Spain"]}</option>
      <option value="Sri Lanka">{translations["Sri Lanka"]}</option>
      <option value="St. Helena">{translations["St. Helena"]}</option>
      <option value="St. Pierre and Miquelon">
        {translations["St. Pierre and Miquelon"]}
      </option>
      <option value="Suriname">{translations["Suriname"]}</option>
      <option value="Svalbard and Jan Mayen Islands">
        {translations["Svalbard and Jan Mayen Islands"]}
      </option>
      <option value="Swaziland">{translations["Swaziland"]}</option>
      <option value="Sweden">{translations["Sweden"]}</option>
      <option value="Switzerland">{translations["Switzerland"]}</option>
      <option value="Taiwan">{translations["Taiwan"]}</option>
      <option value="Tajikistan">{translations["Tajikistan"]}</option>
      <option value="Tanzania">{translations["Tanzania"]}</option>
      <option value="Thailand">{translations["Thailand"]}</option>
      <option value="Togo">{translations["Togo"]}</option>
      <option value="Tokelau">{translations["Tokelau"]}</option>
      <option value="Tonga">{translations["Tonga"]}</option>
      <option value="Trinidad">{translations["Trinidad"]}</option>
      <option value="Trinidad and Tobago">
        {translations["Trinidad and Tobago"]}
      </option>
      <option value="Tunisia">{translations["Tunisia"]}</option>
      <option value="Turkey">{translations["Turkey"]}</option>
      <option value="Turkmenistan">{translations["Turkmenistan"]}</option>
      <option value="Turks and Caicos Islands">
        {translations["Turks and Caicos Islands"]}
      </option>
      <option value="Tuvalu">{translations["Tuvalu"]}</option>
      <option value="Uganda">{translations["Uganda"]}</option>
      <option value="Ukraine">{translations["Ukraine"]}</option>
      <option value="United Arab Emirates">
        {translations["United Arab Emirates"]}
      </option>
      <option value="United Kingdom">{translations["United Kingdom"]}</option>
      <option value="United States">{translations["United States"]}</option>
      <option value="Uruguay">{translations["Uruguay"]}</option>
      <option value="Uzbekistan">{translations["Uzbekistan"]}</option>
      <option value="Vanuatu">{translations["Vanuatu"]}</option>
      <option value="Vatican City State (Holy See)">
        {translations["Vatican City State (Holy See)"]}
      </option>
      <option value="Venezuela">{translations["Venezuela"]}</option>
      <option value="Viet Nam">{translations["Viet Nam"]}</option>
      <option value="Virgin Islands (British)">
        {translations["Virgin Islands (British)"]}
      </option>
      <option value="Virgin Islands (U.S.)">
        {translations["Virgin Islands (U.S.)"]}
      </option>
      <option value="Wales">{translations["Wales"]}</option>
      <option value="Wallis and Futuna Islands">
        {translations["Wallis and Futuna Islands"]}
      </option>
      <option value="Western Sahara">{translations["Western Sahara"]}</option>
      <option value="Yemen">{translations["Yemen"]}</option>
      <option value="Zambia">{translations["Zambia"]}</option>
      <option value="Zimbabwe">{translations["Zimbabwe"]}</option>
    </select>
  );
};
