"use client";
import React, { useEffect, useState } from "react";

const sixSenseFields = {
  address_6sense: "",
  State_6sense__c: "",
  zip_6sense: "",
  city_6sense: "",
  Country_6sense__c: "",
  Company_6sense__c: "",
  Industry_6sense__c: "",
  Revenue_Range_6sense__c: "",
  Company_Phone_6sense__c: "",
  employee_count_6sense: "",
};

const SixSense = () => {
  const [sixSenseVals, setSixSenseVals] = useState(sixSenseFields);

  const sixSenseInit = companyDetails => {
    setSixSenseVals({
      address_6sense: companyDetails.address,
      State_6sense__c: companyDetails.state,
      zip_6sense: companyDetails.zip,
      city_6sense: companyDetails.city,
      Country_6sense__c: companyDetails.country,
      Company_6sense__c: companyDetails.name,
      Industry_6sense__c: companyDetails.industry,
      Revenue_Range_6sense__c: companyDetails.revenue_range,
      Company_Phone_6sense__c: companyDetails.phone,
      employee_count_6sense: companyDetails.employee_count,
    });
  };

  const process6senseData = textResponseData => {
    if (textResponseData === "") {
      return;
    }
    const jData = JSON.parse(textResponseData);
    sixSenseInit(jData.company);

    ///  push to window/localstorage anyway
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      company_name: jData.company.name,
      industry: jData.company.industry,
      domain: jData.company.domain,
      sic_description: jData.company.sic_description,
      country: jData.company.country,
      state: jData.company.state,
      city: jData.company.city,
      revenue_range: jData.company.revenue_range,
      employee_range: jData.company.employee_range,
      segments: jData.segments,
    });
    // Missing Buying Stage and Profile Fit
    if (
      jData &&
      jData.scores &&
      Object.keys(jData.scores).length > 0 &&
      jData.scores.brightcove
    ) {
      window.dataLayer.push({
        buying_stage: jData.scores.brightcove.buying_stage, // REPLACE ME
        profile_fit: jData.scores.brightcove.profile_fit, // REPLACE ME
      });
    }
  };

  useEffect(() => {
    window._6si = window._6si || [];
    window._6si.push(["enableEventTracking", true]);
    window._6si.push(["setToken", "ef5b5d4a978dfe78150fe72cbf49d6cc"]);
    window._6si.push(["setEndpoint", "b.6sc.co"]);

    if (
      typeof window.sessionStorage.six_sense_data !== "undefined" &&
      typeof localStorage._6senseCompanyDetails === "undefined"
    ) {
      const six_sense_data = window.sessionStorage.getItem("six_sense_data");
      try {
        window.localStorage.setItem("_6senseCompanyDetails", six_sense_data);
        window._storagePopulated = true;
      } catch (e) {
        console.error("error setting 6sense data: ", e);
      }
    }
    if (
      window._storagePopulated ||
      typeof localStorage._6senseCompanyDetails !== "undefined"
    ) {
      process6senseData(localStorage._6senseCompanyDetails, true);
    } else {
      window._6si.push(["enableCompanyDetails", true, process6senseData]);
      // window._6si.push(['setEpsilonKey', '662f7c7309b9fbe408e4c5c4f90fa86572685b92'])
    }

    // const gd = document.createElement('script')
    // gd.type = 'text/javascript'
    // gd.async = true
    // gd.src = '//j.6sc.co/6si.min.js'
    // const s = document.getElementsByTagName('script')[0]
    // s.parentNode.insertBefore(gd, s)
    //eslint-disable-next-line
  }, []);

  return Object.keys(sixSenseFields).map(val => {
    return (
      <input
        type="hidden"
        key={val}
        name={val}
        id={val}
        value={sixSenseVals[val]}
      />
    );
  });
};

export default SixSense;
