import styled, { keyframes } from "styled-components";
import { BREAK_POINTS } from "@/src/lib/breakPoints";

export const FormBlock = styled.section`
  padding: 60px;
  width: 100%;

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 0px;
  }
`;
export const FormBlockInner = styled("div")<{
  $backgroundColor?: string;
}>`
  max-width: 1280px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: 4px;
  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    flex-direction: column;
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    min-height: 100vh;
  }
`;

export const FormBlockText = styled("div")<{
  $textColor?: string;
}>`
  width: 50%;
  padding: 60px;
  color: ${({ $textColor }) => $textColor || "#fff"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2,
  p {
    padding-bottom: 30px;
    font-family: "articulat-cf", sans-serif;
  }
  h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 3rem;
    font-family: var(--primary-font-family), "articulat-cf", sans-serif;
    font-weight: 700;
  }
  h5 {
    text-transform: uppercase;
    font-family: var(--primary-font-family), "articulat-cf", sans-serif;
  }
  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    padding: 30px;
    width: calc(100% - 60px);
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 30px 30px 0;
    h2 {
      font-size: 1.8rem;
      padding-bottom: 10px;
    }

    p {
      padding-bottom: 0;
    }
  }
`;
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const FormBlockLoadSpin = styled("span")<{
  $backgroundColor?: string;
}>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid;
  border-radius: 12px;
  border-color: transparent;
  border-bottom-color: ${({ $backgroundColor }) => $backgroundColor};
  animation: ${rotate} 0.8s linear infinite;
  position: relative;
  top: 7px;
  margin-right: 12px;
`;
export const FormBlockForm = styled("form")<{
  $textColor?: string;
  $backgroundColor?: string;
}>`
  color: ${({ $textColor }) => $textColor || "#fff"};
  padding: 60px;
  width: 50%;
  font-family: "articulat-cf", sans-serif;
  /* HIDE SCROLLBAR */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: hidden;
  height: auto;
  input[type="text"],
  input[type="email"] {
    width: 100%;
  }
  input[name="FirstName"],
  input[name="first_name"],
  input[name="LastName"],
  input[name="last_name"],
  select[name="country"] {
    width: 50%;
  }
  select[name="profile_solution_use_case"],
  select[name="state"],
  input[type="tel"],
  select[name="Country"] {
    width: 100%;
  }
  textarea[name="comments"],
  #comments {
    width: 100%;
  }
  &.productinterest_leadform,
  &.content-registration-en {
    select[name="country"] {
      width: 100%;
    }
  }
  input,
  select,
  textarea {
    width: 100%;
    padding: 20px 0px;
    font-size: 1rem;
    font-family: "articulat-cf", sans-serif;
    color: ${({ $textColor }) => $textColor || "#fff"};
    background-color: ${({ $backgroundColor }) => $backgroundColor || "#000"};
    border-width: 1px;
    border-color: ${({ $textColor }) =>
      `transparent transparent ${$textColor || "#fff"}`};
    outline: none;
  }

  select {
    padding: 19px 0;
    width: 100%;
  }
  button {
    background: transparent;
    float: right;
    font-weight: 900;
    border: 0;
    color: ${({ $textColor }) => $textColor || "#fff"};
    font-family: "articulat-cf", sans-serif;
    display: flex;
    align-items: center;
    gap: 8px;
    &:hover {
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.6;
      cursor: initial;
    }
  }
  input[name="receive_updates"] {
    margin-right: 10px;
  }
  #switch label {
    padding-left: 0;
  }
  label div {
    float: left;
    margin: 0 20px 0 0;
  }
  &::-webkit-input-placeholder {
    color: ${({ $textColor }) => $textColor || "#fff"};
  }
  &::-ms-input-placeholder {
    color: ${({ $textColor }) => $textColor || "#fff"};
  }
  &::placeholder {
    color: ${({ $textColor }) => $textColor || "#fff"};
  }
  small {
    display: block;
    margin-bottom: 30px;
  }

  .terms-checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    span {
      padding-left: 10px;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    input {
      margin-top: 25px;
      margin-bottom: 25px;
      width: 25px;
      height: 25px;
    }

    a {
      color: white;
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  @media only screen and (${BREAK_POINTS.TABLET_PORTRAIT_MAX}) {
    padding: 30px;
    width: 100%;
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 0 30px 30px 30px;
    overflow-y: hidden;

    button {
      display: flex;
      justify-content: end;
      align-items: center;
      width: 100%;
      padding: 20px 0;
    }

    input[name="FirstName"],
    input[name="first_name"],
    input[name="LastName"],
    input[name="last_name"],
    input[type="tel"],
    input[type="email"],
    input[type="text"],
    select[name="country"] {
      width: 100%;
      padding: 14px 0;
    }

    > div label {
      padding: 0;
      font-size: 1rem;
    }
  }
`;

export const FormBlockThanks = styled("div")<{
  $textColor?: string;
  $slug?: string;
  $smallForm?: any;
}>`
  /* Thank you get demo */
  max-width: 1280px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(217, 217, 214);
  color: ${({ $textColor }) => $textColor || "#000"};
  border-radius: 4px;
  padding: ${({ $slug }) => ($slug === "get-demo-form" ? "20px" : "60px")};
  min-height: 50vh;
  > div {
    width: 50%;
    display: inline-block;
    p {
      max-width: 100%;
    }
  }
  h2 {
    max-width: ${({ $smallForm }) => ($smallForm ? "unset" : "100%")};
    text-transform: uppercase;
    margin-top: 20px;
    text-align: center;
    font-size: 3rem;
    font-family: var(--primary-font-family), sans-serif;
    margin-bottom: 30px;
  }
  p {
    max-width: ${({ $smallForm }) => ($smallForm ? "unset" : "50%")};
    &.thanks-message {
      text-align: center;
      padding-bottom: 20px;
      width: ${({ $smallForm }) => ($smallForm ? "unset" : "50%")};
    }
  }

  a {
    justify-content: center;
    p {
      color: #000;
      text-decoration: underline;
      text-underline-offset: 2px;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: #4ea9d1;
      }
      &:after {
        content: none;
      }
    }
  }
  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    padding: 0;
  }
`;
