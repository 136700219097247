const fieldMap = {
  "analyst-report": {
    fields: [
      {
        name: "EmailAddress",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      {
        name: "FirstName",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "Company",
        type: "text",
        placeholder: "Company",
        required: true,
      },
      {
        name: "PhoneNumber",
        type: "tel",
        placeholder: "Phone Number",
        autocomplete: "tel",
      },
      {
        name: "Country",
        type: "select",
        placeholder: "Country",
        required: true,
      },
      {
        name: "JobTitle",
        type: "text",
        placeholder: "Job Title",
        required: true,
      },
      {
        name: "Demo_Privacy_Policy_Accepted__c",
        type: "checkbox",
        placeholder: "I understand and agree to the Brightcove Privacy Policy.",
        required: true,
      },
    ],
  },
  "content-registration-en": {
    fields: [
      {
        name: "EmailAddress",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      {
        name: "FirstName",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "Company",
        type: "text",
        placeholder: "Company",
        required: true,
      },
      {
        name: "PhoneNumber",
        type: "tel",
        placeholder: "Phone Number",
        autocomplete: "tel",
      },
      {
        name: "Country",
        type: "select",
        placeholder: "Country",
        required: true,
      },
      {
        name: "JobTitle",
        type: "text",
        placeholder: "Job Title",
        required: true,
      },
      {
        name: "Demo_Privacy_Policy_Accepted__c",
        type: "checkbox",
        placeholder: "I understand and agree to the Brightcove Privacy Policy.",
        required: true,
      },
    ],
  },
  contactSales: {
    fields: [
      {
        name: "FirstName",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "EmailAddress",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      {
        name: "Company",
        type: "text",
        placeholder: "Company",
        required: true,
      },
      {
        name: "PhoneNumber",
        type: "tel",
        placeholder: "Phone Number",
        required: true,
        autocomplete: "tel",
      },
      {
        name: "Country",
        type: "select",
        placeholder: "Country",
        required: true,
      },
      {
        name: "SolutionUseCase",
        type: "select",
        placeholder: "I want to use video for:",
        options: {},
      },
      {
        name: "Demo_Privacy_Policy_Accepted__c",
        type: "checkbox",
        placeholder: "I understand and agree to the Brightcove Privacy Policy.",
        required: true,
      },
    ],
  },
  "Virtual-Event-Streaming": {
    fields: [
      {
        name: "FirstName",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "Email",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      {
        name: "company",
        type: "text",
        placeholder: "Company",
        required: true,
      },
      {
        name: "phone",
        type: "tel",
        placeholder: "Phone Number",
        required: true,
        autocomplete: "tel",
      },
      {
        name: "country",
        type: "select",
        placeholder: "Country",
        required: true,
      },
      // {
      //   name: 'profile_solution_use_case',
      //   type: 'select',
      //   placeholder: 'I want to use video for:',
      //   options: {},
      // },
    ],
  },
  productinterest_leadform: {
    fields: [
      {
        name: "email",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      {
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "company",
        type: "text",
        placeholder: "Company",
        required: true,
      },
      {
        name: "country",
        type: "select",
        placeholder: "Country",
        required: true,
      },
      {
        name: "title",
        type: "text",
        placeholder: "Job Title",
      },
    ],
  },
  gotowebinar_registration: {
    fields: [
      {
        name: "email",
        type: "email",
        placeholder: "Work Email",
        autocomplete: "email",
      },
      {
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        autocomplete: "fname",
      },
      {
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        autocomplete: "lname",
      },
      {
        name: "company",
        type: "text",
        placeholder: "Company",
      },
      {
        name: "phone",
        type: "tel",
        placeholder: "Phone Number",
      },
      {
        name: "country",
        type: "select",
        placeholder: "Country",
        autocomplete: "tel",
      },
      {
        name: "title",
        type: "text",
        placeholder: "Job Title",
      },
    ],
  },
  "beta-sign-up": {
    fields: [
      {
        name: "Email",
        type: "email",
        placeholder: "Work Email",
        autocomplete: "email",
      },
      {
        name: "FirstName",
        type: "text",
        placeholder: "First Name",
        autocomplete: "fname",
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        autocomplete: "lname",
      },
      {
        name: "company",
        type: "text",
        placeholder: "Company",
      },
      {
        name: "phone",
        type: "tel",
        placeholder: "Phone Number",
        autocomplete: "tel",
      },
      {
        name: "country",
        type: "select",
        placeholder: "Country",
      },
      {
        name: "job_title",
        type: "text",
        placeholder: "Job Title",
      },
    ],
  },
  video_data_collection: {
    fields: [
      {
        name: "email",
        type: "email",
        placeholder: "Work Email",
        autocomplete: "email",
      },
      {
        name: "first_name",
        type: "text",
        placeholder: "First Name",
        autocomplete: "fname",
      },
      {
        name: "last_name",
        type: "text",
        placeholder: "Last Name",
        autocomplete: "lname",
      },
      {
        name: "company",
        type: "text",
        placeholder: "Company",
      },
      {
        name: "phone",
        type: "tel",
        placeholder: "Phone Number",
        autocomplete: "tel",
      },
      {
        name: "country",
        type: "select",
        placeholder: "Country",
      },
    ],
  },
  newsletter_signup: {
    fields: [
      {
        name: "FirstName",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "EmailAddress",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      {
        name: "Company",
        type: "text",
        placeholder: "Company",
        required: true,
      },
      {
        name: "Country",
        type: "select",
        placeholder: "Country",
        required: true,
      },
      {
        name: "Demo_Privacy_Policy_Accepted__c",
        type: "checkbox",
        placeholder: "I understand and agree to the Brightcove Privacy Policy.",
        required: true,
      },
    ],
  },
  partner_inquiry_new: {
    fields: [
      {
        name: "email",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "company",
        type: "text",
        placeholder: "Company",
        required: true,
      },
      {
        name: "country",
        type: "select",
        placeholder: "Country",
        required: true,
      },
      {
        name: "offerselect",
        type: "select",
        options: [
          { label: "Select a partner program" },
          {
            label: "Master License Partner",
            value: "7016f0000013ObyAAE",
          },
          {
            label: "Solution Partner",
            value: "701140000027TNcAAM",
          },
          {
            label: "Technology Partner",
            value: "701140000027TNXAA2",
          },
        ],
        required: true,
      },
    ],
  },
  "brightcove-ad-network-638085246043663568": {
    fields: [
      {
        name: "FirstName",
        type: "text",
        placeholder: "First Name",
        required: true,
        autocomplete: "fname",
      },
      {
        name: "LastName",
        type: "text",
        placeholder: "Last Name",
        required: true,
        autocomplete: "lname",
      },
      {
        name: "Email",
        type: "email",
        placeholder: "Work Email",
        required: true,
        autocomplete: "email",
      },
      // {
      //   name: 'country',
      //   type: 'text',
      //   placeholder: 'Country',
      //   required: false,
      // },
      // {
      //   name: 'state',
      //   type: 'text',
      //   placeholder: 'State',
      //   required: false,
      // },
      {
        name: "Company",
        type: "text",
        placeholder: "Company",
        required: false,
      },
      // {
      //   name: 'job_title',
      //   type: 'text',
      //   placeholder: 'Job Title',
      //   required: false,
      // },

      {
        name: "phone",
        type: "text",
        placeholder: "Phone Number",
        required: false,
      },
    ],
  },
};

export default fieldMap;
