"use client";
import React, { useState } from "react";

export const UseCaseSelect = props => {
  const [selectVal, setSelectVal] = useState("");
  const [showTextArea, setShowTextArea] = useState(false);
  const [showEventSize, setShowEventSize] = useState(false);
  const [notDemo, setNotDemo] = useState(props.slug !== "get-demo-form");
  const selectChange = e => {
    setSelectVal(e.target.value);
    setShowEventSize(e.target.value === "Virtual / Hybrid Events");
    setShowTextArea(e.target.value !== "");
  };
  const { translations } = props;

  return (
    <>
      <select
        name="SolutionUseCase"
        value={selectVal}
        onChange={selectChange}
        required
      >
        <option value="">{`${translations["I want to use video for"]}*`}</option>
        <option value="Internal Communication / Live Streaming">
          {translations["Internal Communication / Live Streaming"]}
        </option>
        <option value="Live Events / 24x7 Channels">
          {translations["Live Events / 24x7 Channels"]}
        </option>
        <option value="Marketing">
          {translations["Marketing / Lead Generation"]}
        </option>
        <option value="Media Monetization">
          {translations["Publishing & Monetization / OTT"]}
        </option>
        <option value="Media Transcoding">
          {translations["Cloud Transcoding / Zencoder"]}
        </option>
        <option value="Virtual / Hybrid Events">
          {translations["Virtual / Hybrid Events"]}
        </option>
      </select>
      {showEventSize && (
        <>
          <br />
          <select name="Profile_Events_Executed_Annually__c" required>
            <option value="">
              {translations["Number of events for this year's portafolio"]}
            </option>
            <option value="1 - 10">{translations["1 - 10"]}</option>
            <option value="11 - 15">{translations["11 - 15"]}</option>
            <option value="16 - 19">{translations["16 - 19"]}</option>
            <option value="20+">{translations["20+"]}</option>
          </select>
        </>
      )}
      {showTextArea && notDemo && (
        <>
          <br />
          <textarea
            id="comments"
            name="CampaignComments_MostRecent"
            rows={4}
            maxLength={2000}
            placeholder={translations["How can we help?"]}
          />
        </>
      )}
    </>
  );
};
