import styled from "styled-components";

export const CallToAction = styled("a")<{
  $underlineColor: string;
  $backgroundColor: string;
  $textColor: string;
  $backgroundButton: string;
  $colorTextButton: string;
}>`
  display: flex;
  align-items: unset;
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-decoration: none;
  gap: 8px;

  .content-cta-icon {
    font-size: 20px;
  }

  p:after {
    content: "";
    display: block;
    width: 0;
    height: 2px;
    background: ${({ $underlineColor }) => $underlineColor};
    transition: width 300ms ease;
    margin-top: 4px;
  }

  &:hover {
    text-decoration: none;
    p:after {
      width: 100%;
    }
  }
  /* Square Button */
  &.square {
    padding: 15px 50px 8px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ $backgroundButton }) =>
      $backgroundButton || "transparent"};
    p:after {
      content: "";
      display: block;
      width: 0;
    }
    p {
      margin-bottom: 0px !important;
      color: ${({ $colorTextButton }) => $colorTextButton || ""};
    }
    &:hover {
      background-color: ${({ $colorTextButton }) =>
        $colorTextButton || "transparent"};
      p {
        color: ${({ $backgroundButton }) => $backgroundButton || ""};
      }
    }
  }
  /* Rounded Button */
  &.rounded {
    padding: 12px 70px 12px 70px;
    display: inline-block;
    border-radius: 50px;
    background-color: ${({ $backgroundButton }) =>
      $backgroundButton || "transparent"};
    border: ${({ $backgroundButton }) =>
      `1px solid ${$backgroundButton || "transparent"}`};
    p:after {
      content: "";
      display: block;
      width: 0;
    }
    p {
      margin-bottom: 0px !important;
      text-align: center;
      color: ${({ $colorTextButton }) => $colorTextButton || ""};
    }
    &:hover {
      background-color: ${({ $colorTextButton }) =>
        $colorTextButton || "transparent"};
      p {
        color: ${({ $backgroundButton }) => $backgroundButton || ""};
      }
    }
  }
`;

export const CallToActionCopy = styled("p")<{
  $download: boolean;
  $textColor: string;
}>`
  font-family: var(--primary-font-family), Arial, Helvetica, sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: ${({ $download }) => ($download ? "left" : "center")};
  margin: 0;
  /* margin-top: 13px; */
  color: ${({ $textColor }) => $textColor};
`;

