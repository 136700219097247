import styled from "styled-components";
import { BREAK_POINTS } from "@/src/lib/breakPoints";

export const ToggleSwitch = styled.div`
  font-family: var(--primary-font-family), sans-serif;
  display: block;
  color: ${({ color }) => color || "#000"};
  font-size: 14px;
  cursor: pointer;
  box-sizing: border-box;

  label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding: 17px 0 0 34px;
    cursor: pointer;
    input {
      display: none;
    }

    div {
      position: relative;
      margin-left: 32px;
      box-sizing: border-box;
      display: block;
      float: right;
      min-width: 27px;
      height: 18px;
      border: 3px solid ${({ color }) => color || "#000"};
      border-radius: 34px;
      margin-right: 34px;
      transition: 0.4s;
      background: #fff;
      &:before {
        position: absolute;
        content: "";
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        height: 10px;
        width: 10px;
        left: 1px;
        top: 1px;
        transition: 0.4s;
      }
    }

    input:checked + div:before {
      background-color: #000;
      transform: translateX(9px);
    }
  }

  @media only screen and (${BREAK_POINTS.MOBILE_MAX}) {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: ${({ color }) => color || "#000"};

    label {
      padding: 17px 0 0 20px;

      div {
        top: 3px;
      }
    }
  }
`;
