import React from "react";
import * as Styles from "./ToggleSwitchStyles";

const ToggleSwitch = ({
  id,
  text,
  name,
  color,
  onChange,
  checked,
  value,
  disabled = false,
  required = false,
}) => {
  return (
    <Styles.ToggleSwitch color={color || "#000"}>
      <label>
        <span className="filter-text">{text || "Yes"}</span>
        <input
          className="filter-toggle"
          type="checkbox"
          required={required}
          name={name || ""}
          id={id}
          checked={checked}
          value={value}
          onChange={onChange}
          disabled={disabled || false}
        />
        <div />
      </label>
    </Styles.ToggleSwitch>
  );
};

export default ToggleSwitch;
