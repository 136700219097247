"use client";
import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Cookie from "js-cookie";
import axios from "axios";
import formLookup from "@/src/lib/formLookup";
import { CountrySelect } from "@/src/components/Global_Components/CountrySelect/CountrySelect";
import { StateSelect } from "@/src/components/Global_Components/StateSelect/StateSelect";
import { UseCaseSelect } from "@/src/components/Global_Components/UseCaseSelect/UseCaseSelect";
import ToggleSwitch from "@/src/components/Global_Components/ToggleSwitch/ToggleSwitch";
import * as Styles from "./FormStyles";
import { WorkEmailError } from "./FormMessaging";
import FormPrePop from "./FormPrePop";
import SixSense from "./SixSense";
import ga from "@/src/lib/googleAnalyticsService";
import isJunk from "@/src/lib/junkDomains";
import CallToAction from "../CallToAction/CallToAction";
import { FaArrowRight } from "react-icons/fa";
import Markdown from "markdown-to-jsx";
import { useEloquaData } from "@/src/context/eloquaDataContext";

function Form(props) {
  const {
    slug,
    title,
    body,
    eloquaFormName,
    salesforceOfferId,
    businessUnitSolutionLine,
    solutionUseCase,
    segment,
    submitText,
    thanksTitle,
    thanksMessage,
    comments,
    callback,
    extraFields,
    smallForm,
    thanksCtAs,
    download,
    offerAssetName,
    offerAssetUrl,
    className,
    backgroundColor,
    headline,
    iconColor,
    translations,
    formElementId,
    lang,
  } = props;
  const endpoint = "/api/form-eloqua";
  const informaticaProxy = "/api/informatica";
  //  variable to override fields if japan
  const [fields, setFields] = useState(formLookup[eloquaFormName].fields);
  const initialFormData = fields.reduce((formDataObj, field) => {
    return Object.assign(formDataObj, {
      [field.name]: "",
    });
  }, {});

  const eloquaData = useEloquaData();

  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [receiveUpates, setReceiveUpdates] = useState(lang === "en");
  const [receivePhoneUpates, setReceivePhoneUpdates] = useState(lang === "en");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [responseChannelID, setResponseChannelID] = useState("");
  const [responseProgramID, setResponseProgramID] = useState("");
  const [formValid, setFormValid] = useState(true);
  const [elqOfferId, setElqOfferId] = useState(salesforceOfferId);
  const [extraContactFields, setExtraContactFields] = useState(extraFields);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [validationData, setValidationData] = useState({
    segment: "",
    description: "",
    corrections: "",
  });
  const [thanksCTAs, setThanksCTAs] = useState<any>("");
  const form = useRef<any>();
  // const thanksCTAStrings = {
  //   'Marketing': `While you are here, check out our <a href="https://www.brightcove.com/${lang}/blog/marketing">blog</a> or start a <a class="" href="https://register.brightcove.com/${lang}/vms">Brightcove trial</a>.`,
  //   'Internal Communication / Live Streaming': `While you are here, check out our <a href="https://www.brightcove.com/${lang}/blog">blog</a> or start a <a href="https://register.brightcove.com/${lang}/evs">Brightcove trial</a>.`,
  //   'Media Monetization': `While you are here, check out our <a href="https://www.brightcove.com/${lang}/blog/media">blog</a> or start a <a href="https://register.brightcove.com/${lang}/video-cloud">Brightcove trial</a>.`,
  //   'Live Events / 24x7 Channels': `While you are here, check out our <a href="https://www.brightcove.com/${lang}/blog/media">blog</a> or start a <a href="https://register.brightcove.com/${lang}/video-cloud">Brightcove trial</a>.`,
  //   'Media Transcoding': `While you are here, check out our <a href="https://www.brightcove.com/${lang}/blog/media">blog</a> or start a <a href="https://app.zencoder.com/signup">Brightcove trial</a>.`
  // }
  const hasEloqua = () => {
    return Object.keys(eloquaData?.eloquaData).length > 0;
  };
  const getCTA = (useCase, lang) => {
    const links = {
      Marketing: {
        blog: `https://www.brightcove.com/${lang}/resources/resource-center/`,
        trial: `https://register.brightcove.com/${lang}/vms`,
      },
      "Internal Communication / Live Streaming": {
        blog: `https://www.brightcove.com/${lang}/resources/resource-center/`,
        trial: `https://register.brightcove.com/${lang}/evs`,
      },
      "Media Monetization": {
        blog: `https://www.brightcove.com/${lang}/resources/resource-center/`,
        trial: `https://register.brightcove.com/${lang}/video-cloud`,
      },
      "Live Events / 24x7 Channels": {
        blog: `https://www.brightcove.com/${lang}/resources/resource-center/`,
        trial: `https://register.brightcove.com/${lang}/video-cloud`,
      },
      "Media Transcoding": {
        blog: `https://www.brightcove.com/${lang}/resources/resource-center/`,
        trial: `https://app.zencoder.com/signup`,
      },
      "Virtual / Hybrid Events": {
        blog: `https://www.brightcove.com/${lang}/resources/resource-center/`,
        trial: `https://register.brightcove.com/${lang}/video-cloud`,
      },
    };
    const blogLink = links[useCase]
      ? links[useCase].blog
      : `https://www.brightcove.com/${lang}/blog`;
    const trialLink = links[useCase]
      ? links[useCase].trial
      : `https://register.brightcove.com/${lang}`;
    return (
      <>
        <CallToAction
          linkPath={blogLink}
          textColor="#08088c"
          iconColor="#08088c"
          callToActionCopy={
            translations["Check out the Brightcove Resource Center"]
          }
          lang={lang}
        />
      </>
    );
  };
  const MessageThanks: any = ({ ctaList, lang }) => {
    return ctaList.map((cta, i) => {
      return (
        <CallToAction
          linkPath={cta.link}
          iconImage={cta.icon || "arrow"}
          textColor="#08088c"
          iconColor="#08088c"
          callToActionCopy={cta.headline}
          lang={lang}
          key={i}
        />
      );
    });
  };
  const Downloads = ({ downloadList, lang }) => {
    return downloadList.children.map((asset, i) => {
      const { description, file } = asset;
      const { url } = file;
      return (
        <CallToAction
          linkPath={url}
          iconImage="download"
          textColor="#08088c"
          iconColor="#08088c"
          lang={lang}
          callToActionCopy={description || "Download"}
          download
          key={i}
        />
      );
    });
  };
  function OfferAsset({ url, lang, name }) {
    return (
      <CallToAction
        linkPath={url}
        lang={lang}
        iconImage={name === "Watch the video" ? "play" : "download"}
        textColor="#08088c"
        iconColor="#08088c"
        callToActionCopy={
          name === "Watch the video" ? "Watch the video" : "download"
        }
        download
      />
    );
  }
  const submitCategory = function (offer, form) {
    let cat;
    if (eloquaFormName == "content-registration-en") {
      return "Whitepaper";
    }
    if (form == "contactSales") {
      return "Contact Sales";
    }
    if (form == "gotowebinar_registration") {
      return "Webinar";
    }
    if (form == "productinterest_leadform") {
      return "Product Inquiry";
    }
    if (offer) {
      if (
        offer.toLowerCase().includes("whitepaper") ||
        offer.toLowerCase().includes("white paper") ||
        offer.toLowerCase().includes("wp")
      ) {
        return "Whitepaper";
      }
      if (
        offer.toLowerCase().includes("datasheet") ||
        offer.toLowerCase().includes("data sheet")
      ) {
        return "Datasheet";
      }
    }
  };

  const submitForm = () => {
    // ?cid=7011O0000037wpk&pid=70130000000kQlL
    setSubmitting(true);
    setErrorMessage("");
    const solutionUseCase =
      form.current &&
      form.current?.SolutionUseCase &&
      form.current.SolutionUseCase.value !== ""
        ? form.current.SolutionUseCase.value
        : null;
    const formFields = new FormData(form.current);
    const formObj: any = {};
    formFields.forEach((val, key) => {
      formObj[key] = val;
    });
    if (
      eloquaFormName === "content-registration-en" ||
      eloquaFormName === "analyst-report"
    ) {
      formObj.Asset_Name = offerAssetName || "";
    }
    if (eloquaFormName === "brightcove-ad-network-638085246043663568") {
      formObj.offer_asset_name = "";
      formObj.assetURL = "";
    }
    if (eloquaFormName === "newsletter_signup") {
      formObj.Optin = "on";
      formObj.OptinPhone = "on";
    }
    if (formObj.Country !== "United States") {
      formObj.State = "---";
    }
    const assetName =
      submitCategory("", form.current) || "Contact Sales Request";
    // formObj.Asset_Name = assetName
    const client = ga.getClientId();
    if (client) {
      formObj.ClientID_GA = client;
    } else {
      formObj.ClientID_GA = "";
    }
    // append additional values from cookie
    if (
      Cookie.get("BC_MKTG_AUTO") &&
      JSON.parse(decodeURIComponent(Cookie.get("BC_MKTG_AUTO") || ""))
    ) {
      const cookieVals = JSON.parse(
        decodeURIComponent(Cookie.get("BC_MKTG_AUTO") || "")
      );
      if (typeof cookieVals.gclid !== "undefined") {
        // format gclid to form name for eloqua
        cookieVals["GA-GCLIDGA_GCLID__c"] = cookieVals.gclid;
        delete cookieVals.gclid;
      }
      Object.keys(cookieVals).forEach(key => {
        // this will take the form contents first but append additional terms
        formObj[key] = formObj[key] ? formObj[key] : cookieVals[key];
      });
    } else {
      // pass the referrer if we don't have any cookies, otherwise it should be in the cookie when it is written
      formObj.ReferringURL = document.referrer;
    }
    // Add FormName to object
    const keyName = "formName";
    formObj[keyName] = eloquaFormName;
    if (form.current.checkValidity()) {
      fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(formObj),
      })
        .then(res => {
          if (res) {
            // @ts-ignore
            if (
              window !== undefined &&
              // @ts-ignore
              window.drift !== undefined
            ) {
              // @ts-ignore
              window.drift.api.collectFormData(formObj, {
                campaignId: 2733179,
              });
            }
            if (solutionUseCase) {
              setThanksCTAs(getCTA(solutionUseCase, lang));
            }
            form.current.reset();
            const eventObj = {
              event: assetName,
              eventAction: "Submit Form",
              eventLabel: salesforceOfferId,
              eventCategory: assetName,
            };
            ga.trackEvent(eventObj);
            if (props.eventObj) {
              ga.trackEvent(props.eventObj);
            }
            setSubmitted(true);
            setSubmitting(false);
            if (callback) {
              callback();
            }
          }
        })
        .catch(e => {
          setSubmitting(false);
          setErrorMessage(e);
          console.error(e);
        });
    } else {
      setSubmitting(false);
      setErrorMessage(
        translations["Please check the fields below and try again."]
      );
    }
  };
  const inputBlurHandler = e => {
    if (e.target.type === "EmailAddress" || e.target.type === "Email") {
      try {
        axios.post(informaticaProxy, { email: e.target.value }).then(resp => {
          const infoResponse = resp.data;
          if (infoResponse && infoResponse.WebServiceResponse) {
            const responseValues =
              infoResponse.WebServiceResponse &&
              infoResponse.WebServiceResponse.VerifyEmailResponse &&
              infoResponse.WebServiceResponse.VerifyEmailResponse
                .VerifyEmailResult
                ? infoResponse.WebServiceResponse.VerifyEmailResponse
                    .VerifyEmailResult
                : {};
            const valData = {
              segment: responseValues.ServiceResult
                ? responseValues.ServiceResult.EmailSegment
                : "",
              description: responseValues.ServiceStatus
                ? responseValues.ServiceStatus.StatusDescription
                : "",
              corrections:
                responseValues.ServiceResult &&
                responseValues.ServiceResult.PossibleCorrections
                  ? responseValues.ServiceResult.PossibleCorrections.string
                  : "",
            };
            setValidationData(valData);
          }
        });
      } catch (e) {
        console.info(e);
      }
    }
  };
  const [isUS, setIsUS] = useState(false);
  const inputChangeHandler = e => {
    if (e.target.type === "EmailAddress" || e.target.type === "email") {
      const isJunkEmail = isJunk(e.target.value);
      setFormValid(!isJunkEmail);
      setErrorMessage(isJunkEmail ? WorkEmailError[lang.toLowerCase()] : "");
    }
    if (e.target.name === "Country") {
      setReceiveUpdates(e.target.value === "United States");
      setIsUS(e.target.value === "United States");
    }
    if (e.target.name === "offerselect") {
      // dropdown mapped to offer ID
      if (e.target.value !== "Select a partner program") {
        const opts = e.target.options;
        const selectedIdx = e.target.options.selectedIndex;
        const selected = opts[selectedIdx];

        const fields = Object.assign(extraFields, {
          leadCTA: selected.label,
          profile_solution_use_case: selected.label,
        });
        setExtraContactFields(fields);
        setElqOfferId(e.target.value);
        setFormData({
          ...formData,
          ...extraContactFields,
        });
      }
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    const BC_REG =
      Cookie.get("BC_REG") &&
      JSON.parse(decodeURIComponent(Cookie.get("BC_REG") || ""));
    const BC_MKTG_AUTO =
      Cookie.get("BC_MKTG_AUTO") &&
      JSON.parse(decodeURIComponent(Cookie.get("BC_MKTG_AUTO") || ""));
    const urlParams = new URLSearchParams(window.location.search);

    // sets to query params or cookies or empty
    setResponseChannelID(
      (urlParams.get("cid") && urlParams.get("cid")) ||
        (BC_REG && BC_REG.cid) ||
        (BC_MKTG_AUTO && BC_MKTG_AUTO.cid) ||
        ""
    );
    setResponseProgramID(
      (urlParams.get("pid") && urlParams.get("pid")) ||
        (BC_REG && BC_REG.pid) ||
        (BC_MKTG_AUTO && BC_MKTG_AUTO.pid) ||
        ""
    );
  }, []);

  // swap fields for Japan
  useEffect(() => {
    if (lang === "ja") {
      const indexFirstName = fields.findIndex(
        f => f.name == "first_name" || f.name == "FirstName"
      ); // find index of first_name  (1)
      const indexLastName = fields.findIndex(
        f => f.name == "last_name" || f.name == "LastName"
      ); // find index of last_name (2)
      const firstLast = indexFirstName < indexLastName; // is the order of the fields [first, last]?

      if (firstLast) {
        // copy array then for indexFirstName become indexOfLastName and indexLastName become indexOfFirstName (swap firstName and LastName)
        const newFields = [...fields];
        [newFields[indexFirstName], newFields[indexLastName]] = [
          newFields[indexLastName],
          newFields[indexFirstName],
        ]; // I think the original code was modifying the main fields const

        // ovveride fields
        setFields(newFields);
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      ...eloquaData?.eloquaData,
    });
    //eslint-disable-next-line
  }, [eloquaData?.eloquaData]);
  const isDemo = slug === "get-demo-form" || smallForm;
  return (
    <Styles.FormBlock
      id={slug}
      className={`${submitting ? "submitting" : ""} ${className}`}
    >
      {!hasEloqua() && <FormPrePop />}
      {!submitted && (
        <Styles.FormBlockInner
          className="form-block-inner"
          $backgroundColor={backgroundColor || "#000"}
        >
          {!isDemo && (
            <Styles.FormBlockText>
              <h2>{title || "See how Brightcove can work for you."}</h2>
              {body && (
                <Markdown>
                  {body || "Talk with one of our video experts."}
                </Markdown>
              )}
            </Styles.FormBlockText>
          )}
          <Styles.FormBlockForm
            ref={form}
            id={formElementId ? formElementId : "contact-form"}
            className={eloquaFormName || "contactSales"}
            $backgroundColor={backgroundColor || "#000"}
            onSubmit={e => {
              e.preventDefault();
              if (formValid) {
                submitForm();
              }
            }}
          >
            {isDemo && headline && <h2>{headline}</h2>}
            {/* <input type="hidden" name="elqFormName" value={eloquaFormName} /> */}
            {/* <input type="hidden" name="response_channel_id" value={responseChannelID} /> */}
            {/* <input type="hidden" name="response_program_id" value={responseProgramID} /> */}
            <input type="hidden" name="OfferID" value={salesforceOfferId} />
            <input type="hidden" name="LP_Name" value={""} />
            <input
              type="hidden"
              name="BusinessUnit_SolutionLine"
              value={businessUnitSolutionLine}
            />
            <input type="hidden" name="LeadSource_MostRecent" value={""} />
            {eloquaFormName !== "brightcove-ad-network-638085246043663568" &&
              ["source", "medium", "campaign", "content", "term"].map(
                (utmTerm, i) => (
                  <input
                    type="hidden"
                    name={`utm_${utmTerm}`}
                    value={""}
                    key={i}
                  />
                )
              )}
            {eloquaFormName !== "brightcove-ad-network-638085246043663568" && (
              <input
                type="hidden"
                name="ReferringURL"
                value={typeof document !== "undefined" ? document.referrer : ""}
              />
            )}
            {eloquaFormName === "content-registration-en" ||
              (eloquaFormName === "analyst-report" && (
                <>
                  <input
                    type="hidden"
                    name="OfferAssetURL"
                    value={
                      offerAssetUrl ||
                      (download && download.file ? download.file.url : null) ||
                      ""
                    }
                  />
                  <input
                    type="hidden"
                    name="FormSubmitted"
                    value={"Content registration [CURRENT]"}
                  />
                  <input
                    type="hidden"
                    name="SolutionUseCase"
                    value={solutionUseCase}
                  />
                </>
              ))}
            <SixSense />
            {/* <input type="hidden" name="elqSiteID" value="906" /> */}
            {/* <input type="hidden" name="spam" value="false" /> */}
            <input
              type="hidden"
              name="Emailvalidation_emailsegment"
              value={validationData.segment}
            />
            <input
              type="hidden"
              name="Emailvalidation_possiblecorrections"
              value={validationData.corrections}
            />
            <input
              type="hidden"
              name="Emailvalidation_statusdescription"
              value={validationData.description}
            />
            <input
              type="hidden"
              name={
                eloquaFormName === "brightcove-ad-network-638085246043663568"
                  ? "comments"
                  : "CampaignComments_MostRecent"
              }
              value={comments || ""}
            />
            <input
              type="hidden"
              name="GA_GCLID_GA_GCLID__c"
              id="GA_GCLID_GA_GCLID__c"
              value=""
            />
            <input
              type="hidden"
              name={
                eloquaFormName === "content-registration-en" ||
                eloquaFormName === "analyst-report"
                  ? "OfferLanguage"
                  : eloquaFormName === "contactSales"
                    ? "Language_fromLP"
                    : "language"
              }
              value={lang.toUpperCase()}
            />
            {extraContactFields &&
              Object.keys(extraContactFields).length > 0 &&
              Object.keys(extraContactFields).map((field, i) => {
                const fieldItem = extraContactFields[field];
                return (
                  <input name={field} type="hidden" value={fieldItem} key={i} />
                );
              })}
            {/* {segment && <input type="hidden" name="BusinessUnit_SolutionLine" value={segment} />} */}
            {/* <SixSense /> */}
            {errorMessage !== "" && (
              <div className="error-message" style={{ color: "red" }}>
                {errorMessage}
              </div>
            )}
            {fields.map(
              (field, index) => {
                if (field.name === "Country" && field.type === "select") {
                  return (
                    <React.Fragment key={index}>
                      <br />
                      <CountrySelect
                        required={field.required}
                        key={field.name + index}
                        onChange={inputChangeHandler}
                        value={formData[field.name]}
                        translations={translations}
                      />
                      <br />
                      <StateSelect hidden={!isUS} required={isUS} />
                      <br />
                    </React.Fragment>
                  );
                }

                if (
                  field.name === "SolutionUseCase" &&
                  field.type === "select"
                ) {
                  return (
                    <UseCaseSelect
                      key={field.name + index}
                      slug={slug}
                      translations={translations}
                    />
                  );
                }
                if (
                  field.name !== "Country" &&
                  field.type === "select" &&
                  field.options
                ) {
                  return (
                    <select
                      name={field.name}
                      onChange={inputChangeHandler}
                      key={index}
                    >
                      {field.options.map(optionVal => (
                        <option
                          key={`opt-${optionVal.value}`}
                          value={optionVal.value}
                        >
                          {optionVal.label}
                        </option>
                      ))}
                    </select>
                  );
                }
                // Agree to terms checkbox
                if (field.name === "Demo_Privacy_Policy_Accepted__c") {
                  return (
                    <label className="terms-checkbox" key={index}>
                      <span>
                        {eloquaFormName === "newsletter_signup" ? (
                          <>
                            I’m submitting this information in order to receive
                            marketing communications from Brightcove, and I
                            understand it may be used as described in the
                            Brightcove{" "}
                            <Link
                              prefetch
                              href={`/${lang}/legal/privacy`}
                              target={"_blank"}
                            >
                              Privacy Policy
                            </Link>
                            .*
                          </>
                        ) : (
                          // Link should be the words "Privacy Policy" in each language.
                          <>
                            {lang === "ja" ? (
                              <>
                                {
                                  translations[
                                    "I understand and agree to the Brightcove Privacy Policy."
                                  ].split("プライバシーポリシー")[0]
                                }
                                <Link
                                  prefetch
                                  href={`/${lang}/legal/privacy`}
                                  target="_blank"
                                >
                                  {translations["Privacy Policy"]}
                                </Link>
                                {
                                  translations[
                                    "I understand and agree to the Brightcove Privacy Policy."
                                  ].split("プライバシーポリシー")[1]
                                }
                                *
                              </>
                            ) : lang === "es" ? (
                              <>
                                {
                                  translations[
                                    "I understand and agree to the Brightcove Privacy Policy."
                                  ].split("Política de Privacidad")[0]
                                }
                                <Link
                                  prefetch
                                  href={`/${lang}/legal/privacy`}
                                  target="_blank"
                                >
                                  {translations["Privacy Policy"]}
                                </Link>
                                {
                                  translations[
                                    "I understand and agree to the Brightcove Privacy Policy."
                                  ].split("Política de Privacidad")[1]
                                }
                                *
                              </>
                            ) : (
                              <>
                                I understand and agree to the Brightcove{" "}
                                <Link
                                  prefetch
                                  href={`/${lang}/legal/privacy`}
                                  target={"_blank"}
                                >
                                  Privacy Policy
                                </Link>
                                .*
                              </>
                            )}
                          </>
                        )}
                      </span>
                      <input
                        type="checkbox"
                        name={field.name}
                        required
                        checked={agreeToTerms}
                        value={`${agreeToTerms}`}
                        onChange={() => {
                          return agreeToTerms
                            ? setAgreeToTerms(false)
                            : setAgreeToTerms(true);
                        }}
                      />
                    </label>
                    // <div id="switch-terms">
                    //   <ToggleSwitch
                    //     id="terms"
                    //     name={'Demo_Privacy_Policy_Accepted__c'}
                    //     text={'I understand and agree to the Brightcove Privacy Policy'}
                    //     color="#FFF"
                    //     onChange={() => {
                    //       return agreeToTerms ? setAgreeToTerms(false) : setAgreeToTerms(true)
                    //     }}
                    //     value={agreeToTerms ? 'on' : 'off'}
                    //     checked={agreeToTerms}
                    //     required
                    //   />
                    // </div>
                  );
                }
                // Put special condition for company do not appear
                return (
                  <input
                    key={field.name + index}
                    type={field.type}
                    autoComplete={field.autocomplete}
                    name={field.name}
                    pattern="^[^<>%$]*$"
                    placeholder={
                      field.required &&
                      field.name !== "phone" &&
                      field.name !== "company"
                        ? `${translations[field.placeholder]}*`
                        : translations[field.placeholder]
                    }
                    required={
                      field.name !== "phone" &&
                      field.name !== "company" &&
                      field.required
                    } // phone doesn't need to be required
                    onChange={inputChangeHandler}
                    onBlur={inputBlurHandler}
                    value={formData[field.name]}
                  />
                );
              }
              // field.name === 'country' && field.type === 'select' ? (
              //   <CountrySelect
              //     key={field.name + index}
              //     onChange={inputChangeHandler}
              //     value={formData[field.name]}
              //   />
              // ) : (
              //   <input
              //     key={field.name + index}
              //     type={field.type}
              //     name={field.name}
              //     placeholder={field.required ? field.placeholder + '*' : field.placeholder}
              //     required={field.required}
              //     onChange={inputChangeHandler}
              //     onBlur={inputBlurHandler}
              //     value={formData[field.name]}
              //   />
              // ),
            )}
            <small>* {translations["Indicates a required field"]}</small>
            {eloquaFormName !== "newsletter_signup" && (
              <div id="switch">
                <ToggleSwitch
                  id={
                    eloquaFormName ===
                    "brightcove-ad-network-638085246043663568"
                      ? "receive_updates"
                      : "Optin"
                  }
                  name={
                    eloquaFormName ===
                    "brightcove-ad-network-638085246043663568"
                      ? "receive_updates"
                      : "Optin"
                  }
                  text={
                    translations[
                      "I want to get Brightcove Communications via email."
                    ]
                  }
                  color="#FFF"
                  onChange={() => {
                    return receiveUpates
                      ? setReceiveUpdates(false)
                      : setReceiveUpdates(true);
                  }}
                  value={receiveUpates ? "on" : "off"}
                  checked={receiveUpates}
                />
              </div>
            )}
            {eloquaFormName !== "brightcove-ad-network-638085246043663568" &&
              eloquaFormName !== "newsletter_signup" && (
                <div id="switch">
                  <ToggleSwitch
                    id="OptinPhone"
                    name={"OptinPhone"}
                    text={
                      translations[
                        "I want to get Brightcove Communications via phone."
                      ]
                    }
                    color="#FFF"
                    onChange={() => {
                      return receivePhoneUpates
                        ? setReceivePhoneUpdates(false)
                        : setReceivePhoneUpdates(true);
                    }}
                    value={receivePhoneUpates ? "on" : "off"}
                    checked={receivePhoneUpates}
                  />
                </div>
              )}
            <>
              <br />
              <button
                type="submit"
                disabled={!formValid || submitting}
                id="form-submit"
              >
                {submitting && (
                  <>
                    <Styles.FormBlockLoadSpin $backgroundColor="#000" />{" "}
                    {submitText || "Get Started"}{" "}
                  </>
                )}
                {!submitting && (
                  <>
                    <FaArrowRight
                      color={
                        backgroundColor === "#000000"
                          ? "#fff"
                          : iconColor
                            ? iconColor
                            : "#fff"
                      }
                    />
                    {/* TODO: Need to move inline style out at some point */}
                    <span
                      style={
                        backgroundColor === "#000000" ? { color: "#fff" } : {}
                      }
                    >
                      {submitText || "Get Started"}
                    </span>
                  </>
                )}
              </button>
            </>
          </Styles.FormBlockForm>
        </Styles.FormBlockInner>
      )}
      {submitted && (
        <Styles.FormBlockThanks $smallForm={smallForm} $slug={slug}>
          <h2>{thanksTitle || "THANKS FOR GETTING IN TOUCH"}</h2>
          <p className="thanks-message">
            {thanksMessage ||
              "Check your inbox for confirmation of your request and the next steps"}
          </p>
          {!thanksCtAs && <div>{thanksCTAs}</div>}
          {thanksCtAs && (
            <MessageThanks ctaList={thanksCtAs} lang={lang}></MessageThanks>
          )}
          {download && <Downloads downloadList={download} lang={lang} />}
          {offerAssetUrl && (
            <OfferAsset lang={lang} url={offerAssetUrl} name={offerAssetName} />
          )}
        </Styles.FormBlockThanks>
      )}
    </Styles.FormBlock>
  );
}

export default Form;
