import { useEloquaData } from "@/src/context/eloquaDataContext";
import React, { useEffect, useContext, useState } from "react";

const visitorDataLookupId = "1f056dfb142f4155aa19132d5913c499";
const contactDataLookupId = "52842ae63d7e4f0f85bbd85d1cb4991d";

const FormPrePop = () => {
  const eloquaData = useEloquaData();
  const getVisitorEmail = getter => getter("V_Email_Address");
  const triggerContactLookup = email => {
    // @ts-ignore
    window._elqQ.push([
      "elqDataLookup",
      escape(contactDataLookupId),
      "<C_EmailAddress>" + escape(email) + "</C_EmailAddress>",
    ]);
  };

  let visitorGetter;
  let lookedUp = false;
  const onElqDataLookupReceived = getLookupValue => {
    /* NOTE: these getter functions return '' by default, not undefined, when a value isn't found or a key isn't recognized */
    const email = getVisitorEmail(getLookupValue);

    if (email && lookedUp == false) {
      visitorGetter = getLookupValue;
      // @ts-ignore
      window.ELQ_PREPOP_DEBUG_HOOKS.onVisitorLookupRetrieved(visitorGetter);
      if (email) {
        triggerContactLookup(email);
        return;
      }
      // Else visitor lookup didn't give us an email; no point attempting a contact lookup.  Drop down to below.
    }
    // Else assume this is a successful contact lookup
    // Compose both lookups into one
    const getLookupValueFromEither = k => visitorGetter(k) || getLookupValue(k);

    const formData = {
      email: getLookupValueFromEither("V_Email_Address"),
      first_name: getLookupValueFromEither("V_First_Name"),
      last_name: getLookupValueFromEither("V_Last_Name"),
      company: getLookupValueFromEither("C_Company"),
      phone: getLookupValueFromEither("V_Business_Phone1"),
      country: getLookupValueFromEither("C_Country"),
      title: getLookupValueFromEither("C_Title"),
    };
    if (lookedUp === false) {
      eloquaData?.setEloquaData(formData);
      lookedUp = true;
    } else {
    }
  };

  const onElqDataLookupFailed = () => {
    lookedUp = true;
  };

  const SetElqContent = () => {
    // This function will exist on the window object if data lookup succeeded
    const getLookupValue =
      // @ts-ignore
      window.GetElqContentPersonalizationValue;
    if (getLookupValue && lookedUp === false) {
      onElqDataLookupReceived(getLookupValue);
    } else {
      onElqDataLookupFailed();
    }
  };

  const eloquaInit = () => {
    // This global function is fired by data lookup when it is successfully retrieved
    // @ts-ignore
    window.SetElqContent = SetElqContent;
    // @ts-ignore
    window.ELQ_PREPOP_DEBUG_HOOKS = {
      onVisitorLookupRetrieved: getter => {},
      onBothLookupsRetrieved: (visitorGetter, contactGetter) => {},
      // getFormDeclarations: () => formDeclarations
    };
    if (lookedUp == false) {
      // @ts-ignore
      window._elqQ.push(["elqDataLookup", escape(visitorDataLookupId), ""]);
    }
  };

  const asyncLoad = () => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.onload = () => {
      eloquaInit();
    };
    s.src = "//img.en25.com/i/elqCfg.min.js";
    const x = document.getElementsByTagName("script")[0];
    x!.parentNode!.insertBefore(s, x);
  };

  useEffect(() => {
    if (lookedUp == false) {
      // @ts-ignore
      window._elqQ = window._elqQ || [];
      // @ts-ignore
      window._elqQ.push(["elqSetSiteId", "906"]);
      // @ts-ignore
      window._elqQ.push(["elqTrackPageView"]);
      eloquaInit();
    }
    //eslint-disable-next-line
  }, []);

  return null;
};

export default FormPrePop;
