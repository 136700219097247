export const WorkEmailError = {
  en: "Please enter your work email address",
  ja: "法人メールアドレスをご入力ください。",
  ko: "업무용 회사이메일주소를 사용해주시기 바랍니다",
  fr: "Entrez votre adresse e-mail professionnelle",
  es: "Por favor, ingresa tu dirección de correo electrónico de trabajo",
  de: "Bitte geben Sie Ihre geschäftliche",
};
// const CountryError = {
//   en: "Please select a country",
//   ja: "Please select a country",
//   ko: "Please select a country",
//   fr: "Please select a country",
//   es: "Please select a country",
//   de: "Please select a country",
// };
