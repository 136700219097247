import React from "react";
import PropTypes from "prop-types";
import { FaArrowRight, FaRegArrowAltCircleDown } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { LuPlay } from "react-icons/lu";
import ga from "@/src/lib/googleAnalyticsService";
import { useLightboxData } from "@/src/context/lightboxContext";
import * as Styles from "./CallToActionStyles";

// Define prop types for better type safety and documentation
interface CallToActionProps {
  linkPath: string | { children: string };
  linkFunction?: () => void;
  callToActionCopy: any;
  iconImage?: string;
  iconColor?: string;
  textColor?: string;
  lightboxData?: any;
  id?: string;
  className?: string;
  queryString?: string;
  download?: boolean;
  playerId?: string;
  blank?: boolean;
  buttonStyle?: string;
  backgroundColor?: string;
  backgroundButton?: string;
  colorTextButton?: string;
  lang: string;
}

const CallToAction: React.FC<CallToActionProps> = ({
  linkPath,
  linkFunction,
  callToActionCopy,
  iconImage,
  iconColor,
  textColor,
  lightboxData,
  id = "",
  className = "",
  queryString = "",
  download = false,
  playerId = "",
  buttonStyle = "",
  backgroundColor = "",
  backgroundButton = "",
  colorTextButton = "",
  lang,
}) => {
  const lightBoxData = useLightboxData();

  // Sanitize and construct the link URL
  const sanitizeLink = (path: string, queryString?: string): string => {
    if (download || path.includes("http")) return path;
    path = path.replace(/\/$/, ""); // Remove trailing slash
    if (!path.includes("http") && !path.includes(lang) && !path.includes("#")) {
      path = path === "/" ? path : `../${lang}/${path}/`;
    }
    if (path.startsWith(lang)) path = `/${path}`;
    if (queryString) path += `?${queryString}`;
    if (!path.endsWith("/")) path += "/";
    return path;
  };

  // Determine the appropriate function for the CTA
  const ctaFunction = () => {
    if (lightboxData?.type) {
      lightBoxData!.setLightBoxData({
        open: true,
        type: lightboxData.type,
        content: lightboxData.content,
        playerId,
      });
    } else if (linkFunction) {
      return linkFunction;
    }
    return undefined;
  };

  const isButton = ["button-outline", "button-solid"].includes(iconImage || "");

  let link =
    typeof linkPath === "string"
      ? linkPath
      : (linkPath && linkPath.children) || "";

  return (
    <div id={id} className={className}>
      <Styles.CallToAction
        href={sanitizeLink(link, queryString)}
        title={callToActionCopy}
        $underlineColor={iconColor || ""}
        $backgroundColor={backgroundColor || ""}
        $textColor={textColor || ""}
        $backgroundButton={backgroundButton || ""}
        $colorTextButton={colorTextButton || ""}
        target={
          link.includes("http") ||
          link.includes("info.brightcove.com") ||
          link.includes("ctfassets.net")
            ? "_blank"
            : undefined
        }
        className={`${isButton ? iconImage : ""} ${
          buttonStyle === "square"
            ? "square"
            : buttonStyle === "rounded"
              ? "rounded"
              : ""
        }`}
        onClick={e => {
          if (link === "" || link === "#") {
            e.preventDefault();
            ctaFunction();
            ga.trackEvent({
              event: "CTA",
              eventAction: callToActionCopy,
              eventLabel: "Play/Lightbox",
            });
          } else {
            ga.trackEvent({
              event: "CTA",
              eventAction: callToActionCopy,
              eventLabel: link,
            });
          }
        }}
      >
        {!isButton &&
          buttonStyle !== "square" &&
          {
            download: (
              <FiDownload color={iconColor} className="content-cta-icon" />
            ),
            downloadCircular: (
              <FaRegArrowAltCircleDown
                color={iconColor}
                className="content-cta-icon"
              />
            ),
            arrow: (
              <FaArrowRight color={iconColor} className="content-cta-icon" />
            ),
            play: <LuPlay color={iconColor} className="content-cta-icon" />,
          }[iconImage || ""]}
        <Styles.CallToActionCopy
          $textColor={textColor || ""}
          $download={download}
        >
          {callToActionCopy}
        </Styles.CallToActionCopy>
      </Styles.CallToAction>
    </div>
  );
};

CallToAction.propTypes = {
  linkPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      children: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  linkFunction: PropTypes.func,
  callToActionCopy: PropTypes.string.isRequired,
  iconImage: PropTypes.string,
  iconColor: PropTypes.string,
  textColor: PropTypes.string,
  lightboxData: PropTypes.shape({
    type: PropTypes.string.isRequired,
    content: PropTypes.any,
  }),
  id: PropTypes.string,
  className: PropTypes.string,
  queryString: PropTypes.string,
  download: PropTypes.bool,
  playerId: PropTypes.string,
  blank: PropTypes.bool,
  buttonStyle: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundButton: PropTypes.string,
  colorTextButton: PropTypes.string,
  lang: PropTypes.string.isRequired,
};

export default CallToAction;
